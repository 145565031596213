import Drawer from "../../Drawer";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Button, Card, FixedLayout, Headline, IconButton, LargeTitle, Slider, Caption, Title, Subheadline} from "@telegram-apps/telegram-ui";
import {participateToRaffle} from "../../../../slices/raffles/thunk";
import {useDispatch, useSelector} from "react-redux";
import {formatNumber} from "../../../../helpers/format_helper";
import chuvak from "../../../../assets/lottie/dotlottie/chuvak.lottie";
import {
    setErrorButtonText,
    setErrorLoader,
    setErrorTitle,
    setFinalModalData,
    setShowConfetti,
    setShowErrorModal
} from "../../../../slices/app/reducer";
import pin from "../../../../assets/lottie/dotlottie/pin.lottie";
import OptimizedLottie from "../../OptimizedLottie";
import {payWithTelegramStars} from "../../../../slices/app/thunk";
import LazyDotLottie from "../../LazyDotLottie";
import ModalConfirm from "../../../../pages/Profile/ModalConfirm";
import {useTonAddress, useTonConnectUI} from "@tonconnect/ui-react";
import IconTon from "../../../icons/IconTon";
import {beginCell} from "@ton/ton";
import {createDepositInvoiceApi} from "../../../../helpers/real_api_helper";

const ModalSelectNumber = (props) => {
    const {isOpen, modalClose, paymentType} = props
    const [raffleTicketNumber, setTicketNumber] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [isValid, setIsValid] = useState(true)
    const {balance} = useSelector(state => state.Balance)
    const {increaseChanceCoins, telegramInvoiceLink, telegramStarsLoading} = useSelector(state => state.App)
    const {selectedRaffle, raffleParticipated} = useSelector(state => state.Raffles)
    const {user} = useSelector(state => state.Auth)
    const intervalRef = useRef(null);
    const prevRaffleParticipatedRef = useRef(null);
    const isFirstRender = useRef(true);
    const holdTimeout = useRef(null);
    const hasModalOpened = useRef(false);

    const [neededAmount, setNeededAmount] = useState(null)
    const [walletBalance, setBalance] = useState(null)
    const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
    const userFriendlyAddress = useTonAddress();
    const [tonConnectUI] = useTonConnectUI();
    const [buttonText, setButtonText] = useState('Yes')

    const participateRaffle = async () => {
        if (selectedRaffle?.took_numbers.includes(raffleTicketNumber))
            dispatch(setShowErrorModal('This number is no longer available as someone else has taken it'))
        else {
            if (paymentType === 'telegram_stars') {
                let payload = JSON.stringify({
                    id: selectedRaffle.id,
                    user_id: user.id,
                    ticketNumber: raffleTicketNumber,
                    type: 'lotty'
                })
                await dispatch(payWithTelegramStars('lotty', payload))
            }
            else if (paymentType === 'ton') {
                setModalConfirmOpen(true)
            }
            else {
                await participate()
            }
        }
    }


    const ConfirmContent = () => (
        <div className={"confirm-content"}>
            <div className={"d-flex justify-content-center flex-column pb-4 text-center"}>
                <Title>Participation in Lotty Box #{selectedRaffle?.id}</Title>
            </div>
            <div className={"d-flex flex-column pb-4 text-center align-items-center"}>
                <Subheadline className={"subtitle-color"}>
                    You need
                </Subheadline>
                <div className={"d-flex gap-1 align-items-center"}>
                    <Title>{selectedRaffle?.ton_price}</Title>
                    <IconTon style={{
                        height: 20,
                        width: 20
                    }} />
                </div>
            </div>
            <div className={"d-flex flex-column justify-content-center pb-4 px-4 align-items-center"}>
                <div className={"d-flex gap-2 align-items-center"}>
                    <Caption className={"subtitle-color"}>
                        Connected Wallet balance:
                    </Caption>
                    <div className={"d-flex gap-1 align-items-center"}>
                        <Caption>{formatNumber(walletBalance)}</Caption>
                        <IconTon style={{
                            height: 15,
                            width: 13
                        }} />
                    </div>

                </div>
            </div>
        </div>
    )

    useEffect(() => {
        const fetchBalance = async () => {
            if (!userFriendlyAddress) return;

            try {
                const response = await fetch(`https://toncenter.com/api/v2/getAddressBalance?address=${userFriendlyAddress}`);
                const data = await response.json();

                if (data.ok) {
                    setBalance(parseFloat(data.result) / 1e9); // Конвертируем из Nano-TON в TON
                } else {
                    throw new Error("Ошибка API");
                }
            } catch (error) {
                console.error("Ошибка при получении баланса:", error);
            }
        };

        fetchBalance();
    }, [userFriendlyAddress]);

    useEffect(() => {
        if (isFirstRender.current) {
            // Пропускаем первый рендер
            isFirstRender.current = false;
            return;
        }

        if (raffleParticipated && raffleParticipated !== prevRaffleParticipatedRef.current) {
            modalClose()
            prevRaffleParticipatedRef.current = raffleParticipated; // Обновляем предыдущее значение
        }
    }, [raffleParticipated]);

    useEffect(() => {
        let neededAmount = Number(selectedRaffle?.ton_price).toFixed(2)
        if (neededAmount > 0) {
            setNeededAmount(neededAmount)
            setButtonText('Deposit '+selectedRaffle.ton_price+' TON')
        }
    }, [balance])

    const participate = useCallback(async () => {
        let data = {
            ticketNumber: raffleTicketNumber,
            lotty_coins: increaseChanceCoins,
            payment_type: paymentType,
            invoice_link: telegramInvoiceLink
        };

        setLoading(true);

        try {
            await dispatch(participateToRaffle(data, selectedRaffle));

            // let pushData = {
            //     ticketNumber: raffleTicketNumber,
            //     raffleId: selectedRaffle.id,
            //     maxParticipants: selectedRaffle.max_participants
            // }
            // await showCongratulateModal(pushData)

        } catch (err) {
            // Здесь можно обработать ошибку, полученную из API
            dispatch(setShowErrorModal(err.data.message))
        } finally {
            setLoading(false);
        }
    }, [dispatch, increaseChanceCoins, modalClose, paymentType, raffleTicketNumber, selectedRaffle, telegramInvoiceLink])

    const selectRandomTicket = useCallback(() => {
        const took = selectedRaffle?.took_numbers; // Список занятых номеров
        const range = [1, selectedRaffle?.max_participants]; // Диапазон доступных номеров

        // Генерация случайного числа в диапазоне
        const getRandomNumber = (min, max) => {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        };

        // Функция для выбора случайного номера, который не входит в список занятых номеров
        if (selectedRaffle?.max_participants !== selectedRaffle?.current_participants) {
            let randomTicket;
            do {
                randomTicket = getRandomNumber(range[0], range[1]);
            } while (took.includes(randomTicket));
            setTicketNumber(randomTicket);
        }
    }, [selectedRaffle?.max_participants, selectedRaffle?.current_participants, selectedRaffle?.took_numbers])

    useEffect(() => {
        if (isOpen && raffleTicketNumber === false) {
            // Только если модал открыт и число еще не было сгенерировано
            selectRandomTicket();
        }
    }, [isOpen]);


    const handleSelectNumber = (value) => {
        setTicketNumber(value)
    }

    useEffect(() => {
        if (raffleTicketNumber < selectedRaffle?.max_participants && raffleTicketNumber > 0) {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('soft')
        }
    }, [raffleTicketNumber, selectedRaffle?.max_participants])


    useEffect(() => {
        if (selectedRaffle?.took_numbers?.includes(raffleTicketNumber)) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [raffleTicketNumber, selectedRaffle]);

    const startAction = (func) => {
        holdTimeout.current = setTimeout(() => {
            if (!intervalRef.current) {
                intervalRef.current = setInterval(() => {
                    func();
                }, 1); // Интервал в 1 мс
            }
        }, 500); // Задержка в 100 мс перед началом интервала
    };

    const stopAction = () => {
        clearTimeout(holdTimeout.current); // Очищаем задержку, если не успел запуститься
        if (intervalRef.current) {
            clearInterval(intervalRef.current); // Очищаем таймер
            intervalRef.current = null; // Сбрасываем ref
        }
    };

    const decrementTicketNumber = () => {
        setTicketNumber(prev => {
            // Фильтруем took_numbers, чтобы получить только свободные номера
            const availableNumbers = Array.from({ length: selectedRaffle.max_participants }, (_, i) => i + 1)
                .filter(num => !selectedRaffle.took_numbers.includes(num));

            // Находим индекс текущего значения в массиве доступных номеров
            const currentIndex = availableNumbers.indexOf(prev);

            // Переходим к предыдущему доступному номеру или возвращаем последний
            const newIndex = currentIndex > 0 ? currentIndex - 1 : availableNumbers.length - 1;
            return availableNumbers[newIndex];
        });
    };

    const incrementTicketNumber = () => {
        setTicketNumber(prev => {
            // Фильтруем took_numbers, чтобы получить только свободные номера
            const availableNumbers = Array.from({ length: selectedRaffle?.max_participants }, (_, i) => i + 1)
                .filter(num => !selectedRaffle.took_numbers.includes(num));

            // Находим индекс текущего значения в массиве доступных номеров
            const currentIndex = availableNumbers.indexOf(prev);

            // Переходим к следующему доступному номеру или возвращаем первый
            const newIndex = currentIndex < availableNumbers.length - 1 ? currentIndex + 1 : 0;
            return availableNumbers[newIndex];
        });
    };

    const deposit = async (uuid) => {
        setLoading(true);
        try {
            const recipient = process.env.REACT_APP_DEPOSIT_HUB_WALLET
            const body = beginCell()
                .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
                .storeStringTail(String(uuid)) // write our text comment
                .endCell();

            const result = await tonConnectUI.sendTransaction({
                validUntil: Math.floor(Date.now() / 1000) + 60,
                messages: [
                    {
                        address: recipient,
                        amount: (parseFloat(selectedRaffle.ton_price) * 1e9).toString(),
                        payload: body.toBoc().toString("base64")
                    },
                ],
            });
            if (result) {
                dispatch(setShowErrorModal('We are processing your payment. You will be notified about result here. Please wait.'))
                dispatch(setErrorTitle('Payment processing'))
                dispatch(setErrorLoader(true))
                dispatch(setErrorButtonText('Close'))
                modalClose()
            }
        } catch (error) {
            setLoading(false)
            console.error(error);
        } finally {
            setLoading(false)
            setModalConfirmOpen(false)
        }
    }

    const prepareDeposit = () => {
        let data = {
            amount: selectedRaffle.ton_price,
            payload: {
                type: 'lotty',
                id: selectedRaffle?.id,
                selectedNumber: raffleTicketNumber
            }
        }
        createDepositInvoiceApi(data)
            .then(async res => {
                await deposit(res.data.uuid)
            })
            .catch(err => {
                dispatch(setShowErrorModal('Error, creating payment request. Please contact customer support'))
            })
    }

    const payWithTon = async () => {
        if (!userFriendlyAddress)
            dispatch(setShowErrorModal('First, connect your wallet on the Wallet page.'))
        else {
            if (walletBalance < selectedRaffle.ton_price)
                dispatch(setShowErrorModal('You don’t have enough funds in your TON wallet. Please top up your wallet first. Needed amount: ' + selectedRaffle.ton_price + ' TON'))
            else
                prepareDeposit()
        }
        setModalConfirmOpen(false)
    }

    const TicketNumberSlider = React.memo(({ raffleTicketNumber, selectedRaffle, handleSelectNumber, checkIfNumberHasBeenTaken }) => {
        const handleChange = useCallback(
            (value) => handleSelectNumber(value),
            [handleSelectNumber]
        );

        return (
            <Slider
                className={`slider-no-bg ticket-number-slider ${checkIfNumberHasBeenTaken() ? 'red-btn' : 'green-btn'}`}
                onChange={handleChange}
                value={raffleTicketNumber}
                max={selectedRaffle?.max_participants}
                min={1}
            />
        );
    });


    return (
        <>
            <ModalConfirm
                content={<ConfirmContent />}
                isOpen={modalConfirmOpen}
                loading={loading}
                modalClose={() => setModalConfirmOpen(false)}
                onConfirm={payWithTon}
                buttonText={buttonText}
            />
            <Drawer
                isOpen={isOpen}
                onClose={modalClose}
            >
                <div className={"d-flex px-3 flex-column gap-3 no-select"} style={{
                    paddingBottom: 100
                }}>
                    <div className={"text-center"}>
                        <LargeTitle>Lotty Box #{selectedRaffle?.id}</LargeTitle>
                    </div>
                    <div className={"d-flex flex-row text-center mb-1 justify-content-center align-items-center gap-1"}>
                        <OptimizedLottie
                            src={chuvak}
                            loop
                            autoplay
                            style={{
                                height: 20,
                                width: 20,
                            }}
                        />
                        <span className={"ton_num"}>
                    {formatNumber(selectedRaffle?.current_participants)} / {formatNumber(selectedRaffle?.max_participants)}
                    </span>
                    </div>
                    <Card className={"p-3 section-bg"}>
                        <div className={"d-flex flex-column w-100"}>
                            <div className={"text-center"}>
                                <div className={""}>
                                    <Headline className={"subtitle-color"}>Choose your lucky number</Headline>
                                </div>
                                <div className={"d-flex gap-2 align-items-center justify-content-center position-relative ticket-btns"}>
                                    <IconButton
                                        onClick={decrementTicketNumber} // Обычный клик с проверкой уменьшения
                                        className={"position-absolute start-0 grey-button"}
                                        size={"l"}
                                        mode={"gray"}
                                        onMouseDown={() => startAction(decrementTicketNumber)} // Запуск при зажатии
                                        onMouseUp={stopAction} // Остановка при отпускании
                                        onMouseLeave={stopAction} // Остановка при уходе курсора
                                        onTouchStart={() => startAction(decrementTicketNumber)} // Запуск на мобильных устройствах
                                        onTouchEnd={stopAction} // Остановка на мобильных
                                    >
                                        -
                                    </IconButton>

                                    <IconButton
                                        onClick={incrementTicketNumber} // Обычный клик с проверкой увеличения
                                        className={"position-absolute end-0 grey-button"} // Измените позицию по необходимости
                                        size={"l"}
                                        mode={"gray"}
                                        onMouseDown={() => startAction(incrementTicketNumber)} // Запуск при зажатии
                                        onMouseUp={stopAction} // Остановка при отпускании
                                        onMouseLeave={stopAction} // Остановка при уходе курсора
                                        onTouchStart={() => startAction(incrementTicketNumber)} // Запуск на мобильных устройствах
                                        onTouchEnd={stopAction} // Остановка на мобильных
                                    >
                                        +
                                    </IconButton>

                                    <LargeTitle

                                        className={`py-3 ${isValid ? 'text-red' : 'text-green'}`}
                                        style={{
                                            fontFamily: "Chakra Petch",
                                            fontSize: 58,
                                            lineHeight: "inherit!important",
                                            transition: '0.2s color'
                                        }}
                                        weight={3}>
                                        {raffleTicketNumber}
                                    </LargeTitle>

                                </div>

                            </div>
                            <div className={"position-relative"}>
                                <div className={"d-block lotty-ticket-info"}>
                                    <span className={"position-absolute start-20"}>1</span>
                                    <span className={"position-absolute end-15"}>{formatNumber(selectedRaffle?.max_participants)}</span>
                                </div>

                                <div className={"header-bg wrapper-slider bg-grey"}>
                                    <Slider
                                        className={`slider-no-bg ticket-number-slider ${isValid ? 'red-btn' : 'green-btn'}`}
                                        onChange={handleSelectNumber}
                                        value={raffleTicketNumber}
                                        max={selectedRaffle?.max_participants}
                                        min={1}
                                    />
                                </div>
                            </div>

                        </div>
                    </Card>
                    {/*<LevelRewards />*/}
                </div>
                <FixedLayout
                    style={{
                        paddingBottom : "calc(var(--safe-bottom-padding))!important"
                    }}
                    className={"safe-padding-bottom main-bg super-z-index px-3 pt-3"}
                    vertical="bottom">
                    <div className={"d-flex flex-column gap-2 w-100 text-center"}>
                        <Button
                            onClick={participateRaffle}
                            loading={loading || telegramStarsLoading}
                            size={"l"}
                            disabled={isValid || loading || telegramStarsLoading}
                            before={
                                <LazyDotLottie
                                    src={pin}
                                    loop
                                    autoplay
                                    style={{
                                        height: 20,
                                        width: 20
                                    }}
                                />
                            }
                            stretched
                        >
                            Confirm
                        </Button>
                    </div>
                </FixedLayout>

            </Drawer>
        </>
    )
}

export default ModalSelectNumber