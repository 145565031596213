import Drawer from "../../Drawer";
import {Button, FixedLayout, Headline} from "@telegram-apps/telegram-ui";
import React from "react";
import work from "../../../../assets/lottie/dotlottie/work.lottie";
import unlock from "../../../../assets/lottie/dotlottie/unlock.lottie";
import {useTaskRow} from "../../../../hooks/useTaskRow";
import OptimizedLottie from "../../OptimizedLottie";
import LazyDotLottie from "../../LazyDotLottie";
import {useSelector} from "react-redux";

const ModalRaffleTasks = (props) => {
    const {isOpen, modalClose, checkCompletion, loading} = props
    const {selectedRaffle} = useSelector((state) => state.Raffles);
    const task = selectedRaffle?.task
    const {getTaskRow} = useTaskRow()

    return (
        <Drawer
            isOpen={isOpen}
            onClose={modalClose}
        >
            <div className={"d-flex flex-column px-3 "} style={{
                paddingBottom: 90
            }}>
                <div className={"d-flex justify-content-center mb-4"}>
                    <OptimizedLottie
                        src={work}
                        loop
                        autoplay
                        style={{
                            height: 100,
                            width: 100,
                        }}
                    />
                </div>
                <div className={"d-block"}>
                    <Headline
                        className={"text-center"}
                        weight={1}
                    >
                        Complete all tasks to open Box #{selectedRaffle?.id}
                    </Headline>
                </div>
                <div className={"requirements d-flex flex-column py-5 px-3 gap-3"}>
                    {task?.conditions &&
                        [...task.conditions]
                            .sort((a, b) => {
                                if (a.type === 'farm_ticket' || a.type === 'farm_premium_ticket') {
                                    return 1; // Перемещаем в конец
                                }
                                if (b.type === 'farm_ticket' || b.type === 'farm_premium_ticket') {
                                    return -1; // Перемещаем в конец
                                }
                                return 0; // Сохраняем порядок для остальных
                            })
                            .map((el, i) => getTaskRow(el, i, task))}

                </div>
            </div>
            <FixedLayout
                style={{
                    paddingBottom : "calc(var(--safe-bottom-padding))!important"
                }}
                className={"safe-padding-bottom main-bg super-z-index p-3"}
                vertical="bottom">
                <div className={"d-flex flex-column gap-2 w-100 text-center"}>
                    <Button
                        loading={loading}
                        size={"l"}
                        before={
                            <LazyDotLottie
                                src={unlock}
                                loop
                                autoplay
                                style={{
                                    height: 20,
                                    width: 20,
                                }}
                            />
                        }
                        mode={'bezeled'}
                        stretched
                        onClick={checkCompletion}
                    >
                        Check completion
                    </Button>
                </div>
            </FixedLayout>
        </Drawer>
    )
}

export default ModalRaffleTasks