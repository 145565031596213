import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useSocket } from "../../context/SocketProvider";
import {deleteRaffleFromList, pushRaffle, updateGeneralRaffleInfo, updatePrivateRaffleInfo} from "../../slices/raffles/thunk";
import {pushTask, updateTasks} from "../../slices/tasks/thunk";
import { updateCounters } from "../../slices/app/thunk";
import {enqueueWinnerData, setRaffleParticipated} from "../../slices/raffles/reducer";
import {setTopUsers, setTopWinners} from "../../slices/users/reducer";
import {setBalance} from "../../slices/balance/reducer";
import {setUpdateData} from "../../slices/games/reducer";
import {setReward, setShowErrorModal} from "../../slices/app/reducer";
import {getBalance} from "../../slices/balance/thunk";
import {getTransactions} from "../../slices/transactions/thunk";
import {useNavigate} from "react-router-dom";

const SocketEventListener = () => {
    const dispatch = useDispatch();
    const { socket, isConnected } = useSocket(); // Используем состояние подключения
    const { raffles, selectedRaffle } = useSelector(state => state.Raffles);
    const {topRaffles } = useSelector(state => state.TopRaffles);
    const { tasks, dailyTasks } = useSelector(state => state.Task);
    const { counters } = useSelector(state => state.App);
    const navigate = useNavigate()

    const handleUpdateCounter = useCallback((data) => {
        // console.log('Counter updated:', data);
        dispatch(updateCounters(data, counters));
    }, [counters, dispatch]);

    const handleUpdateTask = useCallback((data) => {
        // console.log('Task updated:', data);
        dispatch(updateTasks(data, tasks, dailyTasks));
    }, [tasks, dailyTasks, dispatch]);

    const handleGeneralUpdate = useCallback((data) => {
        // console.log('General Raffle updated:', data);
        dispatch(updateGeneralRaffleInfo(data, raffles, topRaffles, selectedRaffle));
    }, [dispatch, raffles, topRaffles, selectedRaffle]);

    const handlePrivateUpdate = useCallback((data) => {
        // console.log('Private Raffle updated:', data);
        dispatch(updatePrivateRaffleInfo(data, raffles, topRaffles));
    }, [dispatch, raffles, topRaffles]);

    const handleLottyFinished = useCallback((data) => {
        // console.log('Lotty finished update:', data);
        // dispatch(setShowWinnerModal(true));
        dispatch(enqueueWinnerData(data));
        // dispatch(setWinnerData(data));
    }, [dispatch]);

    const handleLottyDeleted = useCallback((data) => {
        // console.log('Lotty deleted update:', data);
        dispatch(deleteRaffleFromList(data, raffles, topRaffles))
    }, [dispatch, raffles, topRaffles]);

    const handleLottyCreated = useCallback((data) => {
        // console.log('Lotty created update:', data);
        dispatch(pushRaffle(data, raffles, topRaffles, counters));
    }, [dispatch, raffles, topRaffles, counters]);

    const handleTaskCreated = useCallback((data) => {
        // console.log('Task created update:', data);
        dispatch(pushTask(data, tasks, dailyTasks))
    }, [dispatch, tasks, dailyTasks]);

    const handleTopUsersUpdate = useCallback((data) => {
        // console.log('Top users update:', data);
        dispatch(setTopUsers(data))
    }, [dispatch]);

    const handleTopWinnersUpdate = useCallback((data) => {
        // console.log('Top winners update:', data);
        dispatch(setTopWinners(data))
    }, [dispatch]);

    const handleBalanceUpdated = useCallback((data) => {
        // console.log('Balance update:', data);
        dispatch(setBalance(data))
    }, [dispatch]);

    const handleLottyWheel = useCallback((data) => {
        dispatch(setUpdateData(data))
    }, [dispatch]);

    const handleRaffleParticipated = useCallback((data) => {
        // console.log('Raffle update:', data);
        dispatch(setRaffleParticipated(data))
    }, [dispatch]);

    const handleError = useCallback((data) => {
        // console.log('Error update:', data);
        dispatch(setShowErrorModal(data.message))
    }, [dispatch]);

    const handleBalanceDeposited = useCallback((data) => {
        console.log('Balance data:', data);
        window.Telegram.WebApp.BackButton.hide()
        navigate('/wallet')
        dispatch(getBalance())
        dispatch(getTransactions())
        let reward = [
            {
                reward_type: data.token,
                amount: data.amount,
                transaction_type: 'credit'
            }

        ]
        dispatch(setReward(reward))
        dispatch(setShowErrorModal(data.message))
    }, [dispatch]);

    useEffect(() => {
        if (!socket || !isConnected) return; // Ждем подключения

        // Подписываемся на события
        socket.on('counter_updated', handleUpdateCounter);

        socket.on('balance_updated', handleBalanceUpdated);
        socket.on('balance_deposited', handleBalanceDeposited);

        socket.on('general_raffle_updated', handleGeneralUpdate);
        socket.on('private_raffle_updated', handlePrivateUpdate);
        socket.on('raffle_finished', handleLottyFinished);
        socket.on('raffle_created', handleLottyCreated);
        socket.on('raffle_deleted', handleLottyDeleted);
        socket.on('raffle_participated', handleRaffleParticipated);

        socket.on('task_updated', handleUpdateTask);
        socket.on('task_created', handleTaskCreated);

        socket.on('update_top_100_users', handleTopUsersUpdate);
        socket.on('update_top_100_winners', handleTopWinnersUpdate);

        socket.on('wheel_paid', handleLottyWheel);

        socket.on('error', handleError);

        // Очистка эффекта
        return () => {
            socket.off('counter_updated', handleUpdateCounter);

            socket.off('balance_updated', handleBalanceUpdated);
            socket.off('balance_deposited', handleBalanceDeposited);

            socket.off('task_updated', handleUpdateTask);
            socket.off('task_created', handleTaskCreated);

            socket.off('general_raffle_updated', handleGeneralUpdate);
            socket.off('private_raffle_updated', handlePrivateUpdate);
            socket.off('raffle_finished', handleLottyFinished);
            socket.off('raffle_created', handleLottyCreated);
            socket.off('raffle_deleted', handleLottyDeleted);
            socket.off('raffle_participated', handleRaffleParticipated);

            socket.off('update_top_100_users', handleTopUsersUpdate);
            socket.off('update_top_100_winners', handleTopWinnersUpdate);

            socket.off('wheel_paid', handleLottyWheel);

            socket.off('error', handleError);
        };
    }, [socket,
        isConnected,
        handleBalanceDeposited,
        handleUpdateCounter,
        handleUpdateTask,
        handleGeneralUpdate,
        handlePrivateUpdate,
        handleLottyFinished,
        handleLottyCreated,
        handleTaskCreated,
        handleLottyDeleted,
        handleTopUsersUpdate,
        handleLottyWheel,
        handleRaffleParticipated,
        handleError
    ]);

    return null;
};

export default SocketEventListener;