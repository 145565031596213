import {Button, Caption, FixedLayout, Headline, Progress, Subheadline, Title} from "@telegram-apps/telegram-ui";
import React, {useEffect, useState} from "react";
import PrizeTable from "../../../../pages/Home/PrizeTable";
import Drawer from "../../Drawer";
import {formatNumber} from "../../../../helpers/format_helper";
import {cancelParticipateRaffleApi} from "../../../../helpers/real_api_helper";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import Prizes from "../Parts/Prizes";
import ModalConfirm from "../../../../pages/Profile/ModalConfirm";
import ParticipantsReminder from "../Parts/ParticipantsReminder";
import TicketNumber from "../Parts/TicketNumber";
import {setShowErrorModal} from "../../../../slices/app/reducer";
import IconTelegramPremium from "../../../icons/IconTelegramPremium";
import {setRaffleParticipated} from "../../../../slices/raffles/reducer";

const ModalRaffleParticipated = (props) => {
    const {isOpen, modalClose, premiumModalNotification} = props
    const [loading, setLoading] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const {selectedRaffle} = useSelector((state) => state.Raffles);
    const dispatch = useDispatch()

    const ConfirmContent = () => (
        <div className={"confirm-content"}>
            <div className={"d-flex justify-content-center flex-column pb-4 text-center"}>
                <Title>Cancel participation in Lotty #{selectedRaffle?.id}</Title>
            </div>
            <div className={"d-flex flex-column justify-content-center pb-4 px-4 align-items-center text-center"}>
                <Caption className={"subtitle-color"}>You’re about to cancel your participation in the Lotty. This will remove your place and you’ll lose what you used to get it, along with the chance to win prizes.</Caption>
            </div>
        </div>
    )

    const cancelParticipate = () => {
        setLoading(true)
        cancelParticipateRaffleApi(selectedRaffle)
            .then(res => {
                toast.success('Success')
                dispatch(setRaffleParticipated(null))
                modalClose()
            })
            .catch(err => {
                dispatch(setShowErrorModal(err.data.message))
            })
            .finally(() => {
                setLoading(false)
            })
    }


    const cancel = () => {
        setConfirmOpen(true);
    }


    return (
        <Drawer
            onClose={modalClose}
            isOpen={isOpen}
        >
            <ModalConfirm
                content={<ConfirmContent />}
                isOpen={confirmOpen}
                modalClose={() => setConfirmOpen(false)}
                onConfirm={cancelParticipate}
                loading={loading}
            />
            <div
                style={{
                    paddingBottom: 90
                }}
                className={"d-flex w-100 flex-column gap-3 justify-content-center align-items-center"}
            >
                <div className={"d-flex gap-1 align-items-center"}>
                    <Title>Lotty Box #{selectedRaffle?.id}</Title>
                    {selectedRaffle?.is_premium && <IconTelegramPremium onClick={() => premiumModalNotification()} style={{
                        width: 20
                    }} />}
                </div>
                <div className={"d-flex flex-row gap-2 align-items-center"}>
                    <Prizes prizes={selectedRaffle?.prizes} />
                </div>
                {/*<div className={"task-icon"}>*/}
                {/*    <MemoizedAvatar src={"/images/winner_list2.png"} size={100} rounded={false} />*/}
                {/*</div>*/}
                <div className={"d-flex gap-3"}>
                   <TicketNumber selectedRaffle={selectedRaffle} />
                </div>
                <div className={"d-flex gap-2 flex-column text-center"}>
                    <Subheadline weight={2}>
                        Participants
                    </Subheadline>
                    <div className={"block w-75 mx-auto justify-content-center"}>
                        <Progress className={"progress-lotty"} value={selectedRaffle?.filled_percentage} />
                    </div>
                    <span
                        className={"ton_num"}
                    >
                        👥 {formatNumber(selectedRaffle?.current_participants)} / {formatNumber(selectedRaffle?.max_participants)}
                    </span>
                </div>
                <ParticipantsReminder />
                <Headline
                    className={"text-center"}
                    level="1"
                    weight="2"
                >
                   Winner places
                </Headline>

                <div style={{
                    position:"relative",
                    zIndex: -1
                }}>
                    <PrizeTable
                        matrix={selectedRaffle?.matrix}
                    />
                </div>

            </div>
            <FixedLayout
                style={{
                    paddingBottom : "calc(var(--safe-bottom-padding))!important"
                }}
                className={"px-3 pt-3 safe-padding-bottom main-bg super-z-index"}
                vertical="bottom">
                <div className={"d-flex flex-column gap-2 w-100 text-center"}>
                    <Button
                        onClick={cancel}
                        className={"bg-danger w-100"}
                    >
                        Cancel
                    </Button>
                </div>
            </FixedLayout>

        </Drawer>
    )
}

export default ModalRaffleParticipated