import './App.css';
import './assets/css/dark.css';
import './assets/css/white.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@telegram-apps/telegram-ui/dist/styles.css';
import {AppRoot} from '@telegram-apps/telegram-ui';
import Route from './Routes';
import React, {useEffect} from "react";
import ErrorModal from "./Components/custom/ErrorModal";
export const App = () => {

    useEffect(() => {
        // Получаем значение переменной --tg-color-scheme
        const theme = getComputedStyle(document.documentElement).getPropertyValue('--tg-color-scheme').trim();

        // Добавляем/удаляем классы на body в зависимости от темы
        if (theme === 'dark') {
            document.body.classList.add('tg-dark');
            document.body.classList.remove('tg-light');
        } else {
            document.body.classList.add('tg-light');
            document.body.classList.remove('tg-dark');
        }
    }, []);

    return (
        <AppRoot className={"no-select page-container"} id={"app-root"}>
            <ErrorModal />
            <Route/>
        </AppRoot>
    )
};
