import { useDispatch } from "react-redux";
import { Button, Headline } from "@telegram-apps/telegram-ui";
import React, { useRef, useState } from "react";
import { setMajority, setShowErrorModal } from "../../slices/app/reducer";
import { setRefIsActiveApi, updateUserApi } from "../../helpers/real_api_helper";
import ReCAPTCHA from 'react-google-recaptcha';

const AlertModal = () => {
    const [isVisible, setIsVisible] = useState(true);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const recaptchaRef = useRef(null);

    const handleYes = async () => {
        if (!recaptchaRef.current) {
            dispatch(setShowErrorModal("Captcha not initialized."));
            return;
        }

        setLoading(true);

        try {
            // Генерируем reCAPTCHA токен перед отправкой
            const token = await recaptchaRef.current.execute();

            if (!token) {
                throw new Error("Verification failed.");
            }

            let data = {
                majority: true,
                token: token
            };

            const res = await updateUserApi(data);

            dispatch(setMajority(true));
            setRefIsActiveApi();

            // Если все успешно, сбрасываем капчу
            recaptchaRef.current.reset();

        } catch (err) {
            if (err.data.message.includes("Verification failed")) {
                dispatch(setShowErrorModal("reCAPTCHA failed. Please try again."));
                recaptchaRef.current.reset();
            } else {
                dispatch(setShowErrorModal("Error occurred. Contact customer support"));
            }
        } finally {
            setLoading(false);
        }
    };

    const handleNo = () => {
        window.Telegram.WebApp.close();
    };

    return (
        <div className={`custom-overlay ${isVisible ? "show" : "hide"}`}>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
            />
            <div className={`custom-modal ${isVisible ? "fade-in" : "fade-out"}`}>
                <div className={"d-flex b-8 justify-content-center flex-column p-3 w-75 modal-block main-bg"}>
                    <div className={"d-flex w-100 flex-column gap-3"}>
                        <div className={"custom-modal-body gap-2 flex-column d-flex text-center"}>
                            <Headline>
                                Are you 18 years old and do you agree to follow
                                <a style={{ color: "#719bd9" }} href={"https://t.me/lotty/278"} target={"_blank"} rel="noopener noreferrer"> The Lotty Laws</a> ?
                            </Headline>
                        </div>
                        <div className={"custom-modal-footer d-flex gap-2"}>
                            <Button onClick={handleNo} stretched mode={"bezeled"}>
                                No
                            </Button>
                            <Button
                                loading={loading}
                                onClick={handleYes}
                                stretched
                            >
                                Yes
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AlertModal;