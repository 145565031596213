import React from 'react';

// import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./slices";
import LazyTonConnectProvider from "./Components/custom/lazy/LazyTonConnectProvider";

const store = configureStore({ reducer: rootReducer, devTools: true });

const { createRoot } = await import('react-dom/client'); // Dynamically import react-dom
const root = createRoot(document.getElementById('root'));

if (process.env.NODE_ENV === 'development') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        trackAllPureComponents: true,
    });
}
root.render(
  <React.Fragment>
      <Provider store={store}>
          <LazyTonConnectProvider manifestUrl={process.env.REACT_APP_MANIFEST_URL}>
              <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <App />
              </BrowserRouter>
          </LazyTonConnectProvider>
      </Provider>
  </React.Fragment>
);
//
// if ('serviceWorker' in navigator) {
//     window.addEventListener('load', () => {
//         navigator.serviceWorker.register('/service-worker.js')
//             .then(registration => {
//                 console.log('Service Worker зарегистрирован с областью:', registration.scope);
//             })
//             .catch(error => {
//                 console.log('Ошибка при регистрации Service Worker:', error);
//             });
//     });
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
