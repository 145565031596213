import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    games: [],
    showSkeleton: false,
    loaded: false,
    selectedGame: null,
    prizeNum: null,
    mustStartSpinning: false,
    prizes: null,
    nextAvailableAt: null
}

const appSlice = createSlice({
    name: "games",
    initialState,
    reducers: {
       setGames(state, action) {
           state.games = action.payload
           state.loaded = true
       },
        setGame(state, action) {
           state.selectedGame = action.payload
        },
        setPrizeNum(state, action) {
           state.prizeNum = action.payload
        },
        setMustSpin(state, action) {
            state.mustStartSpinning = action.payload
        },
        setUpdateData(state, action) {
           state.prizes = action.payload.prizes;
           state.nextAvailableAt = action.payload.next_available_at
        },
        setPrizes(state, action) {
           state.prizes = action.payload
        }
    }
})

export const {
    setGames,
    setGame,
    setPrizeNum,
    setMustSpin,
    setUpdateData,
    setPrizes
} = appSlice.actions

export default appSlice.reducer