import {Headline, LargeTitle, Title} from "@telegram-apps/telegram-ui";
import React from "react";
import LottyCountdown from "./LottyCountdown";
import {useSelector} from "react-redux";
import {motion} from 'framer-motion'

const PrepareRaffle = (props) => {
    const { winnerData} = useSelector(state => state.Raffles)
    return (
        <div className={"d-flex align-items-center flex-column"} style={{
            minHeight: '80vh'
        }}>
            <div className={"d-block text-center"}>
                <div className={"d-flex justify-content-center flex-column align-items-center gap-2"}>
                    
                        <motion.div
                            initial={{ y: -100, opacity: 0 }} // Начальная позиция -100 по оси Y, скрыт
                            animate={{ y: 0, opacity: 1 }} // Конечная позиция на месте, видимый
                            transition={{
                                type: "spring", // Используем spring анимацию для плавности
                                stiffness: 100, // Сила пружины
                                damping: 25, // Уменьшение амплитуды
                                duration: 1, // Длительность анимации
                            }}
                        >
                            <LargeTitle
                                style={{
                                    fontFamily: "Chakra Petch",
                                }}
                            >
                                Lotty #{winnerData?.raffle}
                            </LargeTitle>
                        </motion.div>

                        <motion.div
                            initial={{ y: -100, opacity: 0 }} // Начальная позиция -100 по оси Y, скрыт
                            animate={{ y: 0, opacity: 1 }} // Конечная позиция на месте, видимый
                            transition={{
                                type: "spring", // Используем spring анимацию для плавности
                                stiffness: 100, // Сила пружины
                                damping: 25, // Уменьшение амплитуды
                                duration: 1, // Длительность анимации
                                delay: 0.5
                            }}
                        >
                            <Title
                                style={{
                                    fontFamily: "Chakra Petch",
                                }}
                            >
                                Get ready!
                            </Title>
                        </motion.div>

                        <motion.div
                            initial={{ y: -100, opacity: 0 }} // Начальная позиция -100 по оси Y, скрыт
                            animate={{ y: 0, opacity: 1 }} // Конечная позиция на месте, видимый
                            transition={{
                                type: "spring", // Используем spring анимацию для плавности
                                stiffness: 100, // Сила пружины
                                damping: 25, // Уменьшение амплитуды
                                duration: 1, // Длительность анимации
                                delay: 1
                            }}
                        >
                            <Headline
                                style={{
                                    fontFamily: "Chakra Petch",
                                }}
                            >
                                The participants are being chosen in
                            </Headline>
                        </motion.div>
                    
                </div>
            </div>

            <div className={"d-flex justify-content-center flex-column align-items-center "}>
                <LottyCountdown />
            </div>
        </div>
    );
};

export default PrepareRaffle;