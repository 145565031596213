import {Badge, Tabbar} from "@telegram-apps/telegram-ui";
import React from 'react';
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import TooltipProfile from "../Components/custom/Profile/TooltipProfile";
import {setCurrentTabbarItem} from "../slices/app/reducer";
import {LuPackageOpen, LuPackageSearch, LuPlay, LuStar, LuTicket, LuWallet} from "react-icons/lu";

const Footer = () => {
    const navigate = useNavigate();
    const { currentTab, counters } = useSelector((state) => state.App);
    const { user } = useSelector((state) => state.Auth);
    const dispatch = useDispatch()

    const tabs = [
        {
            id: 'rewards',
            text: 'Earn',
            Icon: (
                <>
                    {counters?.count_available_tasks > 0 && (
                        <Badge
                            className="position-absolute start-50"
                            type="number"
                        >
                            {counters?.count_available_tasks}
                        </Badge>
                    )}
                    <LuStar size={24}/>
                </>
            ),
            path: "/rewards"
        },
        { id: 'lottys', text: 'Boxes', Icon:
            <>
                {counters?.count_available_lotty > 0 && (
                    <Badge
                        className="position-absolute start-50"
                        mode={"critical"}
                        type="number"
                    >
                        {counters?.count_available_lotty}
                    </Badge>
                )}
                <LuPackageSearch size={24} />
            </>
            , path: "/lotty" },
        { id: 'friends', text: 'Tickets', Icon: <LuTicket
                style={{
            }} size={24} />, path: "/friends" },
        { id: 'wallet', text: 'Wallet', Icon: <LuWallet size={24} />, path: "/wallet" }
    ];

    const changeTab = (tab) => {
        if (window.Telegram.WebApp)
            window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');

        console.time("Navigation Time");
        navigate(tab.path);
        console.timeEnd("Navigation Time");

        dispatch(setCurrentTabbarItem(tab.id));
    };

    return (
        <Tabbar className="footer">
            {tabs.slice(0, 2).map((el, i) => (
                <Tabbar.Item
                    className="tabbar-item"
                    key={i}
                    text={el.text}
                    selected={el.id === currentTab}
                    onClick={() => changeTab(el)}
                >
                    {el.Icon}
                </Tabbar.Item>
            ))}

            {/* Центральная кнопка с тултипом */}
            <Tabbar.Item>
                <TooltipProfile user={user} />
            </Tabbar.Item>

            {tabs.slice(2).map((el, i) => (
                <Tabbar.Item
                    className="tabbar-item"
                    key={i + 2}
                    text={el.text}
                    selected={el.id === currentTab}
                    onClick={() => changeTab(el)}
                >
                    {el.Icon}
                </Tabbar.Item>
            ))}
        </Tabbar>
    );
};

export default Footer;