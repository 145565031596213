import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import {Button, Headline, Spinner, Subheadline} from "@telegram-apps/telegram-ui";
import React, {useCallback, useEffect, useState} from "react";
import {setShowErrorModal, setErrorTitle, setErrorButtonText, setErrorLoader} from "../../slices/app/reducer";
import { emojiConverter } from "../../helpers/format_helper";
import {payWithTelegramStars} from "../../slices/app/thunk";
import {createLogApi, updateSubscriptionApi} from "../../helpers/real_api_helper";
import {setErrorCode} from "../../slices/error/reducer";
import IconTelegramStar from "../icons/IconTelegramStar";
import {unbanUser} from "../../slices/users/thunk";
import {ClipLoader} from "react-spinners";

const ErrorModal = () => {
    const { showErrorModal, errorTitle, errorLoader, errorButtonText } = useSelector((state) => state.App);
    const {telegramStarsLoading} = useSelector(state => state.App)
    const {user} = useSelector(state => state.Auth)
    const dispatch = useDispatch();
    const [isVisible, setIsVisible] = useState(false);

    // Устанавливаем стандартное значение заголовка, если errorTitle отсутствует
    const title = errorTitle || "Attention!";

    useEffect(() => {
        if (showErrorModal) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }, [showErrorModal]);

    // useEffect(() => {
    //     const handleEvent = async (res) => {
    //         if (res.status === 'paid') {
    //             dispatch(unbanUser(res.url))
    //         }
    //     };
    //
    //     window.Telegram.WebApp.onEvent('invoiceClosed', handleEvent);
    //
    //     return () => {
    //         window.Telegram.WebApp.offEvent('invoiceClosed', handleEvent);
    //     };
    // }, []);

    const handleClose =  () => {
        setIsVisible(false); // Устанавливаем анимацию скрытия
        setTimeout(() => {
            dispatch(setShowErrorModal(null)); // Закрываем модалку
            dispatch(setErrorTitle(null)); // Сбрасываем заголовок
            dispatch(setErrorButtonText('Ok'))
            dispatch(setErrorLoader(false))
        }, 300); // Убираем модалку после окончания анимации
    };

    if (!showErrorModal && !isVisible) return null;

    const appRoot = document.getElementById("app-root");
    if (!appRoot) {
        console.error("AppRoot элемент не найден в DOM");
        return null;
    }

    const sendTelegramInvoice = (type) => {
        let payload = JSON.stringify({
            user_id: user.id,
            type: type
        })
        dispatch(payWithTelegramStars(type, payload))
    }

    const renderContent = () => {
        if (Array.isArray(showErrorModal)) {
            return showErrorModal.map((item, index) => (
                <Subheadline key={index} className={"text-break"}>
                    {emojiConverter(index + 1)} {item}
                </Subheadline>
            ));
        } else {
            return (
                <div dangerouslySetInnerHTML={{ __html: showErrorModal }}></div>
            );
        }
    };

    const content = (
        <div className={`custom-overlay ${isVisible ? "show" : "hide"}`}>
            <div className={`custom-modal ${isVisible ? "fade-in" : "fade-out"}`}>
                <div className={"d-flex b-8 justify-content-center flex-column p-3 w-75 modal-block main-bg"}>
                    <div className={"d-flex w-100 flex-column gap-3"}>
                        <div className={"custom-modal-body gap-2 flex-column d-flex text-center"}>
                            <Headline weight={2}>{title}</Headline>
                            {renderContent()}
                        </div>
                        {errorLoader &&
                            <div className={"d-flex justify-content-center align-items-center"}>
                                <ClipLoader color="#747474" />
                            </div>
                        }
                        <div className={"custom-modal-footer d-flex gap-2 flex-column"}>
                            <Button onClick={() => handleClose()} stretched mode={"bezeled"}>
                                {errorButtonText}
                            </Button>
                            {showErrorModal && showErrorModal.includes("You have been blocked.") &&
                                <Button
                                    stretched
                                    disabled={telegramStarsLoading}
                                    mode={'gray'}
                                    style={{
                                        color: '#fdb345'
                                    }}
                                    loading={telegramStarsLoading}
                                    onClick={() => sendTelegramInvoice('lotty_unban')}
                                    before={
                                        <IconTelegramStar
                                            style={{
                                                height: 20,
                                                width: 20
                                            }}
                                        />
                                    }
                                    className={"grey-button"}
                                >
                                   Unban 5000
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(content, appRoot);
};

export default ErrorModal;