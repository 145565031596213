import {Button, Caption, FixedLayout, Headline, Progress, Subheadline, Title} from "@telegram-apps/telegram-ui";
import React, {useEffect, useMemo, useState} from "react";
import PrizeTable from "../../../../pages/Home/PrizeTable";
import Drawer from "../../Drawer";
import {useDispatch, useSelector} from "react-redux";
import { formatNumber} from "../../../../helpers/format_helper";
import {checkTaskApi} from "../../../../helpers/real_api_helper";
import ModalSelectNumber from "./ModalSelectNumber";
import Prizes from "../Parts/Prizes";
import IconTelegramStar from "../../../icons/IconTelegramStar";
import free from "../../../../assets/lottie/dotlottie/fire.lottie";
import ModalConfirm from "../../../../pages/Profile/ModalConfirm";
import {getBalance} from "../../../../slices/balance/thunk";
import ModalRaffleTasks from "./ModalRaffleTasks";
import MemoizedAvatar from "../../Profile/MemoizedAvatar";
import ParticipantsReminder from "../Parts/ParticipantsReminder";
import {setShowErrorModal} from "../../../../slices/app/reducer";
import {lotty_styles} from "../../../../helpers/lotty_styles";
import IconTelegramPremium from "../../../icons/IconTelegramPremium";
import LazyDotLottie from "../../LazyDotLottie";
import IconTon from "../../../icons/IconTon";
import {useTonAddress} from "@tonconnect/ui-react";

const ModalRaffle = (props) => {
    const {balance} = useSelector(state => state.Balance)
    const {telegramStarsLoading} = useSelector(state => state.App)
    const {isOpen, modalClose, premiumModalNotification} = props
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [showSelectNumber, setShowSelectNumber] = useState(false)
    const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
    const [paymentType, setPaymentType] = useState('task')
    const [telegramInvoiceLink, setTelegramInvoiceLink] = useState(false)
    const [showModalRaffleTasks, setShowModalRaffleTasks] = useState(false)

    const {selectedRaffle} = useSelector((state) => state.Raffles);

    const key = selectedRaffle?.style_number;

    const style = useMemo(
        () => lotty_styles.find((el) => el.style_number === selectedRaffle?.style_number),
        [selectedRaffle?.style_number]
    );


    useEffect(() => {
        dispatch(getBalance())
    }, [dispatch])

    const closeRaffleTasks = () => {
        setShowModalRaffleTasks(false)
    }

    const checkCompletion =  () => {
        setLoading(true)
        checkTaskApi(selectedRaffle?.task)
            .then(async res => {
                setPaymentType('task')
                await closeRaffleTasks()
                await setShowSelectNumber(true)
            })
            .catch(err => {
                dispatch(setShowErrorModal(err.data.message.split("\n")))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const closeSelectNumber = () => {
        setShowSelectNumber(false)
        setPaymentType('task')
        setTelegramInvoiceLink(false)
        modalClose()
    }

    const payWithSilverStars = () => {

        if (balance?.star_balance >= 2000) {
            setShowSelectNumber(true)
            setPaymentType('silver_stars')
        }
        else {
            dispatch(setShowErrorModal('Insufficient funds'))
        }
        setModalConfirmOpen(false)
    }

    const playFree = () => {
        if (selectedRaffle?.task?.conditions?.length > 0)
            setShowModalRaffleTasks(true)
        else
            setShowSelectNumber(true)

    }



    const payWithYellowStars = () => {
        setShowSelectNumber(true)
        setPaymentType('telegram_stars')
    }

    return (
        <Drawer
           isOpen={isOpen}
           onClose={modalClose}
        >
            {/*{selectedRaffle?.is_premium &&*/}
            {/*    <div className={"stars"} id={'stars'}>*/}
            {/*        <StarsParticlesInCard position={{*/}
            {/*            x: 50, y: 15*/}
            {/*        }} uniqueKey={'modal'}/>*/}
            {/*    </div>*/}
            {/*}*/}

            <ModalSelectNumber
                isOpen={showSelectNumber}
                paymentType={paymentType}
                telegramInvoiceLink={telegramInvoiceLink}
                modalClose={closeSelectNumber}
            />
            <ModalRaffleTasks
                loading={loading}
                isOpen={showModalRaffleTasks}
                modalClose={closeRaffleTasks}
                checkCompletion={checkCompletion}
            />
            <div
                className={"d-flex w-100 flex-column gap-3 justify-content-center align-items-center"}
                style={{
                    paddingBottom: selectedRaffle?.current_participants !== selectedRaffle?.max_participants ? '170px' : ''
                }}
            >
                <div className={"task-icon"}>
                    <MemoizedAvatar src={`/images/lotties/${style?.picture}`} size={100} rounded={true} />
                </div>
                <div className={"d-flex gap-1 align-items-center"}>
                    <Title>Lotty Box #{selectedRaffle?.id}</Title>
                    {selectedRaffle?.is_premium && <IconTelegramPremium onClick={() => premiumModalNotification()} style={{
                        width: 20
                    }} />}
                </div>
                <div className={"d-flex flex-row gap-2 align-items-center"}>
                    <Prizes prizes={selectedRaffle?.prizes} />
                </div>
                <div className={"d-flex gap-2 flex-column text-center"}>
                    <Subheadline weight={2}>
                        Participants
                    </Subheadline>
                    <div className={"block w-75 mx-auto justify-content-center"}>
                        <Progress className={"progress-lotty"} value={selectedRaffle?.filled_percentage} />
                    </div>
                    <span
                        className={"ton_num"}
                    >
                        👥 {formatNumber(selectedRaffle?.current_participants)} / {formatNumber(selectedRaffle?.max_participants)}
                    </span>
                </div>
                <ParticipantsReminder />
                <Headline
                    className={"text-center"}
                    level="1"
                    weight="2"
                >
                   Box table
                </Headline>
                <div style={{
                    position:"relative",
                    zIndex: -1
                }}>
                    <PrizeTable
                        matrix={selectedRaffle?.matrix}
                    />
                </div>
            </div>
            {selectedRaffle?.current_participants !== selectedRaffle?.max_participants &&
                <FixedLayout
                    style={{
                        paddingBottom : "calc(var(--safe-bottom-padding))!important"
                    }}
                    className={"safe-padding-bottom main-bg super-z-index px-3 pt-3"}
                    vertical="bottom">
                    <div className={"d-flex flex-column gap-2 w-100 text-center white-button"}>
                        <Button
                            className={"button-white"}
                            mode={'white'}
                            before={
                                <LazyDotLottie
                                    src={free}
                                    loop
                                    autoplay
                                    style={{
                                        height: 20,
                                        width: 20,
                                    }}
                                />
                            }
                            loading={loading}
                            onClick={() => {
                               playFree()
                            }}
                        >
                            Claim Free
                        </Button>
                        {/*<Caption className={"subtitle-color"} weight={1} style={{*/}
                        {/*}}>OR</Caption>*/}
                        <div className={"d-flex gap-2"}>
                            <Button
                                mode={'gray'}
                                onClick={() => {
                                    setShowSelectNumber(true)
                                    setPaymentType('ton')
                                }}
                                before={
                                    <IconTon
                                        style={{
                                            height: 15,
                                            width: 15
                                        }}
                                    />
                                }
                                className={`w-100 grey-button`}
                            >
                                {selectedRaffle?.ton_price}
                            </Button>
                            <Button
                                mode={'gray'}
                                style={{
                                    color: '#fdb345'
                                }}
                                onClick={() => payWithYellowStars()}
                                loading={telegramStarsLoading}
                                before={
                                    <IconTelegramStar
                                        style={{
                                            height: 20,
                                            width: 20
                                        }}
                                    />
                                }
                                className={"w-100 grey-button"}
                            >
                                {selectedRaffle?.telegram_star_price}
                            </Button>
                        </div>
                    </div>
                </FixedLayout>
            }
        </Drawer>
    )
}

export default ModalRaffle