import {Button} from "@telegram-apps/telegram-ui";

import React from "react";
import Drawer from "../../Components/custom/Drawer";
import {FaRegQuestionCircle} from "react-icons/fa";

const ModalConfirm = (props) => {
    const {isOpen, modalClose, onConfirm, loading, content, title = 'Do you confirm this action?', buttonText = 'Yes'} = props

    return (
        <Drawer
            isOpen={isOpen}
            onClose={modalClose}
            >
            <div className={"p-2"}>
                <div className={"d-flex flex-column"}>
                    {content}
                    <div className={"d-flex justify-content-center"}>
                        <div className="alert justify-content-center border-dashed d-flex align-items-center gap-3" role="alert">
                            {/*<FaRegQuestionCircle />*/}
                            <div className={"text-center subtitle-color"}>
                                <div dangerouslySetInnerHTML={{__html: title}}></div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={"section-bg p-3"}>
                    <div className={"d-flex flex-column w-100 gap-2"}>

                        <Button
                            loading={loading}
                            onClick={onConfirm}
                            disabled={loading}
                            stretched
                        >
                            {buttonText}
                        </Button>
                        <Button
                            className={"grey-button"}
                            onClick={() => modalClose()}
                            stretched
                            mode={"gray"}
                        >
                            Cancel
                        </Button>

                    </div>
                </div>

            </div>
        </Drawer>
    )
}

export default ModalConfirm