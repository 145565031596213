import {APIClient} from "./api_helper";

const api = new APIClient()

export const authTgUser = (data) => api.create('/auth', data)

export const getRandomPrize = (game, coins, payment_method, invoice_link) => {
    const data = {
        lotty_coins: coins,
    };

    if (payment_method !== null) {
        data.payment_method = payment_method;
    }

    if (invoice_link !== null) {
        data.invoice_link = invoice_link;
    }

    return api.create(`/games/${game.id}/run`, data);
}
export const getTasksApi = () => api.get('/tasks')
export const getFriendsApi = (page) => api.get('/friends', {'page': page})
export const getTicketTransactionsApi = (page) => api.get('/friends/transactions', {'page': page})
export const getTransactionsApi = (page) => api.get('/transactions', {'page': page})

export const checkTaskApi = (task) => api.get(`/tasks/${task.id}/check`)

export const getRafflesApi = (page, type, innerType, showTop = false) => api.get('/raffles',
    {
        page: page,
        'filter[type]' : type,
        'filter[innerType]' : innerType,
        'filter[showTop]' : showTop ? 1 : 0
    })

export const getRaffleApi = (raffle) => api.get(`/raffles/${raffle}`)

export const participateRaffleApi = (raffle, data) => api.create(`/raffles/${raffle.id}/participate`, data)
export const cancelParticipateRaffleApi = (raffle) => api.create(`/raffles/${raffle.id}/cancel-participation`)

export const getRaffleWinnersApi = (page, raffle) => api.get(`/raffles/${raffle.id}/winners`, {page: page})
export const getCountsApi = () => api.get(`/counters`)
export const getBalanceApi = () => api.get(`/balance`)
export const convertTokensApi = (data) => api.create('/balance/convert', data)

export const createInvoiceLinkApi = (data) => api.create(`/payments/create-invoice`, data)
export const createDepositInvoiceApi = (data) => api.create(`/payments/create-deposit-invoice`, data)
export const createLogApi = (data) => api.create(`/logs`, data)

export const getGamesApi = () => api.get(`/games`)
export const getGameApi = (id) => api.get(`/games/${id}`)

export const withdrawApi = (data) => api.create('/payments/withdraw', data)

export const updateNotificationApi = (notification) => api.put(`/notifications/${notification}/seen`)
export const updateUserApi = (data) => api.put(`/users`, data)
export const getNotificationsApi = () => api.get(`/notifications`)
export const getCurrentUserApi = () => api.get(`/users`)

export const getCurrentSuperBoss = () => api.get(`/users/superboss`)
export const becomeSuperBossApi = () => api.create(`/users/superboss`)
export const setRefIsActiveApi = () => api.create(`/friends/active`)
export const checkHeartbeat = () => api.get('/heartbeat')
export const updateSubscriptionApi = (data) => api.put('/update-subscription', data)
export const unbanUserApi = (data) => api.create('/users/unban', data)

export const getTopWinnersApi = () => api.get(`/top/winners`)
export const getTopLottyUsersApi = () => api.get(`/top/lotty`)
export const getTopRefsApi = () => api.get(`/top/refs`)

export const getCurrentUserWinInfoApi = () => api.get(`/top/winners/current`)
export const getCurrentUserLottyInfoApi = () => api.get(`/top/lotty/current`)
export const getCurrentUserRefsInfoApi = () => api.get(`/top/refs/current`)



export const getTopUsersApi = () => api.get(`/users/top-100-users`)


