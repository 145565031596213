import config, {app} from '../config'
import {createLogApi} from "./real_api_helper";
import {toast} from "react-toastify";
import {setShowErrorModal} from "../slices/app/reducer";


export const getInviteLink = (user) => {
    if (user) {
        return `https://t.me/${app.APP_NAME}/app?startapp=${user.id}`;
    }
}

export const inviteFriends = (user) => {
    const url = getInviteLink(user)
    const text = 'Welcome to Lotty! Invite friends and loot TON'
    window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`)
}

export const openTelegramLink = (link, task = false) => {
    window.Telegram.WebApp.openTelegramLink(link)
    window.Telegram.WebApp.HapticFeedback.impactOccurred('soft')
    let data = {
        type : 'open_link',
        data : {'link' : link}
    }
    if (task)
        data['loggable'] = {type: 'task', id: task.id}
    createLogApi(data)
}

export const openLink = (link, task = false) => {
    let data = {
        type : 'open_link',
        data : {'link' : link}
    }
    if (task)
        data['loggable'] = {type: 'task', id: task.id}

    createLogApi(data)
    window.Telegram.WebApp.openLink(link)
}

export const openUser = (user) => {
    if (user?.username) {
        window.Telegram.WebApp.HapticFeedback.impactOccurred('soft')
        window.Telegram.WebApp.openTelegramLink(`https://t.me/${user.username}?profile`)

        let userId = user.tg_id || user.telegram_id
        let data = {
            type : 'open_profile',
            loggable : {type: 'telegram_user', id: userId}
        }
        createLogApi(data)
    }


}

export const openTgUser = (user, payment_type = false) => {
    if (user?.username)
        return (
            <a
                style={{
                    maxWidth: 150
                }}
                rel="noreferrer"
                target={"_blank"}
                href={`https://t.me/${user.username}?profile`}
                className={`link-color text-truncate d-block ${payment_type === 'telegram_stars' ? 'golden-color' : payment_type === 'ton' ? 'blue-color' : ''}`}                role={"button"}>{user?.username}</a>
        )
    else
        return (
            <span className={"text-truncate"}>
                 {user?.name}
            </span>

        )
}

export const shareStory = (media_link, params) => {
    let data = {
        type : 'share_story',
        data : {media_link}
    }
    createLogApi(data)
    window.Telegram.WebApp.shareToStory(media_link, params)
}


export const addToHomeScreen = (dispatch, task = false) => {
    try {
        const isMobile =
            window.Telegram &&
            window.Telegram.WebApp &&
            ["android", "ios"].includes(window.Telegram.WebApp.platform);

        if (isMobile) {
            const url = `https://t.me/${config.app.APP_NAME}/app?startapp&addToHomeScreen`;
            let data = {
                type : 'add_to_home_screen',
                data : {link: url}
            }
            if (task)
                data['loggable'] = {type: 'task', id: task.id}
            createLogApi(data)
            // openLink(url)
            window.Telegram.WebApp.addToHomeScreen()
        } else {
            dispatch(setShowErrorModal("This feature is available only on mobile devices."))
        }
    } catch (e) {
        console.error("Error in addToHomeScreen:", e);
    }
};

export const checkHomeScreen = () => {
    try {
        let res = window.Telegram.WebApp.checkHomeScreenStatus()
        console.log(res)
    }
    catch (e)
    {
        console.log(e)
    }
}

export const sendTonTransaction = (user) => {
    const link = `https://app.tonkeeper.com/transfer/UQBaXP8k_bsJadijGp0BQtlXEZAQySG-tcQUvUGdrqQNKuaq?amount=100000000&text=${user.id}`
    let data = {
        type : 'pending_ton_transaction',
        data : {link}
    }
    createLogApi(data)

    window.Telegram.WebApp.openLink(link)
}