import {Caption, Headline, Title} from "@telegram-apps/telegram-ui";
import React from "react";
import WinnerTable from "../../../../pages/Home/WinnerTable";
import Drawer from "../../Drawer";
import {formatNumber} from "../../../../helpers/format_helper";
import Prizes from "../Parts/Prizes";
import MemoizedAvatar from "../../Profile/MemoizedAvatar";
import {useSelector} from "react-redux";
import IconTelegramPremium from "../../../icons/IconTelegramPremium";

const ModalRaffleFinished = (props) => {
    const {isOpen, modalClose, premiumModalNotification} = props
    const {selectedRaffle} = useSelector((state) => state.Raffles);

    return (
        <Drawer
            isOpen={isOpen}
            onClose={modalClose}
        >
            <div className={"d-flex w-100 flex-column gap-3 p-3 justify-content-center align-items-center"}>
                <div className={"task-icon"}>
                    <MemoizedAvatar src={"/images/winner_list2.png"} size={100} rounded={true} />
                </div>
                <div className={"d-flex gap-1 align-items-center"}>
                    <Title>Lotty Box #{selectedRaffle?.id}</Title>
                    {selectedRaffle?.is_premium && <IconTelegramPremium onClick={() => premiumModalNotification()} style={{
                        width: 20
                    }} />}
                </div>
                <div className={"d-flex flex-row gap-2 align-items-center"}>
                    <Prizes prizes={selectedRaffle?.prizes} />
                </div>
                <div>
                    <Caption
                        className={"ton_num"}
                    >
                        👥 {formatNumber(selectedRaffle?.current_participants)}
                    </Caption>
                </div>

                <div className={"text-center"}>
                    <Headline
                        className={"text-center"}
                        level="1"
                        weight="2"
                    >
                        List of participants
                    </Headline>
                    <Caption className={"subtitle-color"}>{selectedRaffle?.updated_at}</Caption>
                </div>
                <WinnerTable
                    selectedRaffle={selectedRaffle}
                />
            </div>
        </Drawer>
    )
}

export default ModalRaffleFinished